<template>
  <el-dialog title="新增" :visible.sync="addVisible" :before-close="closeEvent" width="600px">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="上级名称:" required>
        <p v-text="name"></p>
      </el-form-item>
      <el-form-item size="small" label="名称:" required>
        <el-input v-model="permissionName" maxlength="12" style="width: 415px" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="KEY:" required>
        <el-input v-model="permissionKey" maxlength="32" style="width: 415px" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="savePermission">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>

export default {
    data() {
        return {
            permissionName: '',
            permissionKey: '',
            /*   requestMappingValue: '',*/
        }
    },
    props: {
        id: String,
        name: String,
        addVisible: Boolean
    },
    methods: {
        closeEvent() {
            this.$emit('closeInsert')
        },
        async savePermission() {
            let data = {
                parentId: this.id,
                permissionName: this.permissionName,
                permissionKey: this.permissionKey,
            };
          let res = await this.$post("/admin/save_opPermission",data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('addSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
