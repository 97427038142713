<template>
  <el-dialog title="添加" width="600px" :visible.sync="addRoleVisible" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="角色名称:" required>
        <el-input v-model="roleName" maxlength="16" style="width: 430px;" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="saveRole">确 定</el-button>
    <el-button size="small" @click="closeEvent">取 消</el-button>
  </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        roleName: ''
      }
    },
    props: {
      addRoleVisible: Boolean
    },
    methods: {
      closeEvent() {
        this.$emit('closeAddRole')
      },
      async saveRole() {
        if (!this.roleName) {
          this.$message.error("请填写角色名称!");
          return;
        }
        let data = {
          roleName: this.roleName
        };
        let res = await this.$post("/admin/saveOpRole", data);
        if (res.code == 200) {
          this.$emit('addRoleSuccess')
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      }
    }
  }
</script>

<style scoped>

</style>
