<template>
  <el-dialog title="编辑" width="600px" :visible.sync="editVersionVisible" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="大类名称:" required>
        <el-input v-model="item.versionName" maxlength="16" style="width: 430px;" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="updateVersion">确 定</el-button>
    <el-button size="small" @click="closeEvent">取 消</el-button>
  </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        item: this.versionData
      }
    },
    props: {
      versionData: Object,
      editVersionVisible: Boolean
    },
    methods: {
      closeEvent() {
        this.$emit('closeEditVersion')
      },
      async updateVersion() {
        if (!this.item.versionName) {
          this.$message.error("请填写版本名称!");
          return;
        }
        let data = {
          id: this.item.id,
          versionName: this.item.versionName
        };
        let res = await this.$post("/admin/saveVersion", data);
        if (res.code == 200) {
          this.$message.success(res.message);
          this.$emit('editVersionSuccess')
        } else {
          this.$message.error(res.message);
        }
      }
    }
  }
</script>

<style scoped>

</style>
