<template>
  <div class="table">
    <div class="e-breadcrumb">权限角色管理</div>
    <div class="e-card">
      <div class="e-card-body" style="padding-top:35px">
        <div class="dictionary-container">
          <div>
            <el-button type="primary" size="small" @click="addVersion">添加版本</el-button>
            <div class="tree-container" style="padding:10px">
              <v-tree v-loading="versionLoading" :nodes="verSions">
                <template slot-scope="{node}">
                  <a href="javascript:;" @click.stop="editVersion(node)" class="v-tree-edit"></a>
                  <a href="javascript:;" @click.stop="toDelVersion(node)" class="v-tree-remove"></a>
                </template>
              </v-tree>
            </div>
          </div>
                    <div>
                      <el-button type="primary" size="small" @click="addRole">添加级别</el-button>
                      <div class="tree-container" style="padding:10px">
                        <v-tree v-loading="roleLoading" :nodes="roles" >
                          <template slot-scope="{node}">
                            <a href="javascript:;" @click.stop="editRole(node)" class="v-tree-edit"></a>
                            <a href="javascript:;" @click.stop="toDelRole(node)" class="v-tree-remove"></a>
                          </template>
                        </v-tree>
                      </div>
                    </div>
          <div style="margin-left: 18px">
            <TabPermissions/>
          </div>
        </div>
      </div>
    </div>

<!--     删除提示框-->
        <el-dialog title="提示" :visible.sync="delVersionVisible" width="300px" center>
          <div class="del-dialog-cnt">是否删除此版本信息？</div>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="delVersion">确 定</el-button>
            <el-button size="small" @click="delVersionVisible = false">取 消</el-button>
          </span>
        </el-dialog>

<!--     删除提示框-->
        <el-dialog title="提示" :visible.sync="delRoleVisible" width="300px" center>
          <div class="del-dialog-cnt">是否删除此角色信息？</div>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="delRole">确 定</el-button>
            <el-button size="small" @click="delRoleVisible = false">取 消</el-button>
          </span>
        </el-dialog>

    <AddVersions
      v-if="addVersionVisible"
      :addVersionVisible="addVersionVisible"
      @closeAddVersion="closeAddVersion"
      @addVersionSuccess="addVersionSuccess"
    />

    <EditVersions
      v-if="editVersionVisible"
      :editVersionVisible="editVersionVisible"
      @closeEditVersion="closeEditVersion"
      @editVersionSuccess="editVersionSuccess"
      :versionData="versionData"
    />

    <AddRole
      v-if="addRoleVisible"
      :addRoleVisible="addRoleVisible"
      @closeAddRole="closeAddRole"
      @addRoleSuccess="addRoleSuccess"
    />

    <EditRole
      v-if="editRoleVisible"
      :editRoleVisible="editRoleVisible"
      @closeEditRole="closeEditRole"
      @editRoleSuccess="editRoleSuccess"
      :roleData="roleData"
    />
  </div>
</template>

<script>
  import AddVersions from "./addVersions";
  import EditVersions from "./editVersions";
  import AddRole from "./addRole";
  import EditRole from "./editRole";
  import TabPermissions from "../permissions/entry";
  import vTree from '@/components/common/v-tree/tree';
  import {dataFormat} from "../../../utils/main";

  export default {
    data() {
      return {
        versionId:'',
        versionLoading: false,
        addVersionVisible: false,
        editVersionVisible: false,
        delVersionVisible: false,
        vOptions: {
          data: {
            simpleData: {
              idKey: "id",
              pIdKey: "parentId"
            },
            key: {
              name: "versionName",
              children: "children"
            }
          }
        },
        versionData: {},
        verSions: [],


        roleLoading: false,
        addRoleVisible: false,
        editRoleVisible: false,
        delRoleVisible: false,
        rOptions: {
          data: {
            simpleData: {
              idKey: "id",
              pIdKey: "parentId"
            },
            key: {
              name: "roleName",
              children: "children"
            }
          }
        },
        roleData: {},
        roles: [],
        // titles: ["大类名称", "编辑", "删除"],
        // btns: ["edit", "delete"],
      };
    },
    async created() {
      this.getVersionList();
      this.getRoleList();
    },
    methods: {
      async getVersionList() {
        this.versionLoading = true;
        let res = await this.$get("/admin/getVersionList");
        let data = dataFormat(this.vOptions, res.versionList);
        this.verSions = data.list;
        this.versionLoading = false;
      },
      //添加版本相关
      addVersion() {
        this.addVersionVisible = true;
      },
      addVersionSuccess() {
        this.getVersionList();
        this.closeAddVersion();
      },
      closeAddVersion() {
        this.addVersionVisible = false;
      },

      //修改版本信息相关
      editVersion(node) {
        this.versionData = {
          id: node.id,
          versionName: node.versionName
        };
        this.editVersionVisible = true;
      },
      editVersionSuccess() {
        this.getVersionList();
        this.closeEditVersion();
      },
      closeEditVersion() {
        this.editVersionVisible = false;
      },
      //删除版本信息相关
      toDelVersion(node) {
        this.versionId = node.id;
        this.delVersionVisible = true;
      },
     async delVersion() {
        let res = await this.$post("/admin/delVersion",{id: this.versionId,});
        if (res.code == 200) {
          this.getVersionList();
          this.$message.success(res.message);
          this.delVersionVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },

      async getRoleList() {
        this.roleLoading = true;
        let res = await this.$get("/admin/getOpRoleList");
        let data = dataFormat(this.rOptions, res.roleList);
        this.roles = data.list;
        this.roleLoading = false;
      },

      //添加角色信息相关
      addRole() {
        this.addRoleVisible = true;
      },

      addRoleSuccess() {
        this.getRoleList();
        this.closeAddRole();
      },
      closeAddRole() {
        this.addRoleVisible = false;
      },

      //修改角色信息相关
      editRole(node) {
        this.roleData = {
          id: node.id,
          roleName: node.roleName
        };
        this.editRoleVisible = true;
      },
      editRoleSuccess() {
        this.getRoleList();
        this.closeEditRole();
      },
      closeEditRole() {
        this.editRoleVisible = false;
      },

      //删除角色信息相关
      toDelRole(node) {
        this.versionId = node.id;
        this.delRoleVisible = true;
      },
     async delRole() {
        let res = await this.$post("/admin/delOpRole",{id: this.versionId,});
        if (res.code == 200) {
          this.getRoleList();
          this.$message.success(res.message);
          this.delRoleVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },
    },

    components: {
      vTree,
      AddVersions,
      EditVersions,
      AddRole,
      EditRole,
      TabPermissions,
    }
  };
</script>

<style scoped>

  .dictionary-container {
    display: flex;
  }

  .tree-container {
    margin-top: 20px;
    margin-right: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
    width: 280px;
    /*  height: 600px;*/
    overflow-y: auto;
    border: 1px #ebeef5 solid;
  }
</style>
